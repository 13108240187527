<!-- Settings -->
<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <div class="m-bot-15">
        <div class="row">
          <div class="col-sm-6 col-xs-12">
            <b-form-group class="mb-3 search-bar">
              <b-input-group size="sm">
                <b-form-input
                  v-model="filter"
                  type="search"
                  id="filterInput"
                  placeholder="Type to Search"
                  auto-complete="nope"
                  v-on:keyup="fetchData(1)"
                ></b-form-input>
                <span class="search-icon" v-if="filter" id="clear-button">
                  <i class="fas fa-times" @click="filter = ''"></i>
                  <b-tooltip target="clear-button" variant="primary"
                    >Clear</b-tooltip
                  >
                </span>
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter ? fetchData(1) : ''"
                    variant="primary"
                    >Search</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <div class="col-sm-2">
            <el-select
              placeholder="All Store"
              v-model="selectedWebsite"
              @change="getWebsitevalues()"
              class="website-based-list"
            >
              <el-option value="" label="All Store"></el-option>
              <el-option
                v-for="(website, index) in websites"
                :key="index"
                :label="website.website_name"
                :value="website.website_id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-sm-4 col-xs-12 text-right">
            <b-button
              class="
                rounded-3
                btn btn-sm btn-primary
                font-weight-bold
                text-uppercase
              "
              variant="primary"
              @click="addCustomer"
              ><i class="fa fa-plus"></i>Add Customer
            </b-button>
            <b-button
              class="
                rounded-3
                btn btn-sm btn-warning
                font-weight-bold
                text-uppercase
                ml-3
              "
              variant="primary"
              @click="ImportCustomer"
              ><i
                class="fa fa-upload"
                style="font-size: 12px; margin-top: -5px"
              ></i>
              Import
            </b-button>
            <b-button
              class="btn-sm btn-filter rounded-3"
              v-b-toggle.collapse-1
              variant="primary"
              id="tooltip-button-variant"
              ><i class="fa fa-filter" aria-hidden="true"></i
            ></b-button>
            <b-tooltip target="tooltip-button-variant" variant="primary"
              >Column Filter</b-tooltip
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 select-website">
          <b-collapse id="collapse-1" class="mb-3">
            <b-card>
              <p class="card-text">
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  v-model="checkAll"
                  @change="handleCheckAllChange"
                  >Select All</el-checkbox
                >
              </p>
              <hr />
              <el-checkbox-group
                v-model="checkedColumns"
                @change="handleCheckedCitiesChange"
                :min="5"
              >
                <el-checkbox
                  v-for="(column, index) in column_list"
                  :label="column.value"
                  :key="index"
                  >{{ column.name }}</el-checkbox
                >
              </el-checkbox-group>
            </b-card>
          </b-collapse>
          <div class="collapse" id="collapseExample">
            <div class="card card-body"></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <el-table
            :data="list"
            border
            element-loading-text="Loading"
            style="width: 100%"
            v-loading="loading"
            :row-key="getRowKeys"
            ref="elTable"
            @sort-change="sortChange"
          >
            <!-- <el-table-column
               :reserve-selection="true"
                type="selection"
                width="55">
               </el-table-column> -->
            <el-table-column
              fixed
              align="center"
              label="Customer No"
              prop="id"
              sortable
              width="140"
              v-if="showAndHideColumn('customer_no')"
            >
              <template slot-scope="scope">
                <el-tag slot="reference" size="mini"
                  ><a href="#" @click.prevent="viewCustomer(scope.row.id)">{{
                    scope.row.customer_id ? scope.row.customer_id : ""
                  }}</a></el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              label="Company Name"
              prop="company_name"
              sortable
              width="160"
              v-if="showAndHideColumn('company')"
            >
              <template slot-scope="scope">
                {{ scope.row.company_name ? scope.row.company_name : "" }}
              </template>
            </el-table-column>
            <el-table-column
              label="Name"
              sortable
              min-width="150"
              v-if="showAndHideColumn('customer_name')"
            >
              <template slot-scope="scope">
                <span
                  >{{ scope.row.first_name ? scope.row.first_name : "" }}
                  {{ scope.row.last_name ? scope.row.last_name : "" }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              label="Store"
              prop="website_name"
              sortable
              width="150"
              v-if="showAndHideColumn('store')"
            >
              <template slot-scope="scope">
                {{
                  scope.row.website_list
                    ? scope.row.website_name: ""
                   
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="Phone I"
              prop="customer_mobile"
              sortable
              width="150"
              v-if="showAndHideColumn('phone1')"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.customer_mobile">
                  +1 {{ scope.row.customer_mobile }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="Phone II"
              prop="customer_phone"
              sortable
              width="150"
              v-if="showAndHideColumn('phone2')"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.customer_phone">
                  +1 {{ scope.row.customer_phone }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="Type"
              prop="customer_type"
              sortable
              width="150"
              v-if="showAndHideColumn('type')"
            >
              <template slot-scope="scope">
                {{
                  scope.row.customer_type
                    ? scope.row.customer_type.type_name
                    : ""
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="Email"
              prop="email_address"
              sortable
              width="200"
              v-if="showAndHideColumn('email')"
            >
              <template slot-scope="scope">
                {{ scope.row.email_address ? scope.row.email_address : "" }}
              </template>
            </el-table-column>
            <el-table-column
              label="Credit Limit"
              prop="credit_limit"
              sortable
              width="150"
              v-if="showAndHideColumn('credit_limit')"
            >
              <template slot-scope="scope">
                ${{
                  scope.row.customer_credit
                    ? scope.row.customer_credit.available_credit.toLocaleString("en-US")
                    : "0.00"
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="Balance"
              prop="customer_credit.balance_showing"
              sortable
              width="150"
              v-if="showAndHideColumn('balance')"
              align="center"
            >
              <template slot-scope="scope">
                <span class="red"
                  >$
                  {{
                    scope.row.customer_credit
                      ? scope.row.customer_credit.balance_showing.toLocaleString("en-US")
                      : "0.00"
                  }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              label="Join Date"
              prop="created_at"
              sortable
              width="130"
              v-if="showAndHideColumn('join_date')"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.created_at | formatDate("DD MMM YYYY") }}
              </template>
            </el-table-column>
            <el-table-column
              label="Last Purchase Date"
              prop="last_purchase"
              sortable
              width="190"
              v-if="showAndHideColumn('last_purchase')"
            >
              <template slot-scope="scope">
                {{ scope.row.latest_invoice ? format_date(scope.row.latest_invoice.created_at):''}}
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              align="center"
              label="Actions"
              width="80"
            >
              <template slot-scope="scope">
                <el-dropdown
                  @command="handleActions($event, scope.row)"
                  size="medium"
                >
                  <i class="flaticon2-menu-1"></i>
                  <el-dropdown-menu slot="dropdown" class="action-dropdown">
                    <el-dropdown-item :command="1"
                      ><i class="fa fa-plus"></i>Add Address</el-dropdown-item
                    >
                    <el-dropdown-item :command="5"
                      ><i class="fa fa-credit-card" aria-hidden="true"></i
                      >Credit</el-dropdown-item
                    >
                    <el-dropdown-item :command="2"
                      ><i class="fa fa-eye"></i>View</el-dropdown-item
                    >
                    <el-dropdown-item :command="3"
                      ><i class="fa fa-pen"></i>Edit</el-dropdown-item
                    >
                    <el-dropdown-item :command="4"
                      ><i class="fa fa-trash"></i>Delete</el-dropdown-item
                    >
                    <el-dropdown-item :command="6" v-if="user.role_id == 1"
                      ><i class="fa fa-compass"></i>Change Password</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <el-pagination
            v-if="!loading"
            style="margin-top: 15px"
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="fetchData"
            :page-count="pagination.last_page"
            :page-sizes="[50, 100, 500, 1000, 2000]"
            :page-size="pagination.per_page"
            :current-page.sync="pagination.current_page"
            :total="pagination.total_rows"
          >
          </el-pagination>
        </div>
      </div>
      <v-dialog />
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  getAllCustomers,
  deleteCustomer,
  changeStatus,
} from "@/api/customer";
import { getAllActiveSites } from "@/api/website";
import CreateAddress from "@/components/CreateAddress/add.vue";
import AddCredit from "@/components/CreateCustomer/add_credit.vue";
//import ImportCustomer from '@/components/CreateCustomer/ImportCustomer';
import { globalEvents } from "@/utils/globalEvents";
import elTableExport from "el-table-export";
import moment from 'moment'
import change_passwordVue from '../../../components/CreateCustomer/change_password.vue';
import JwtService from "@/core/services/jwt.service";
export default {
  name: "customers",
  data() {
    return {
      loading: false,
      list: [],
      user: [],
      websites: [],
      pagination: {
        total_rows: 1,
        current_page: 1,
        last_page: null,
        per_page: 50,
      },
      filter: null,
      userContestBusy: false,
      selectedWebsite: null,
      clear: null,
      column_list: [
        {
          name: "Customer No.",
          value: "customer_no",
        },
        {
          name: "Customer Name",
          value: "customer_name",
        },
        {
          name: "Store",
          value: "store",
        },
        {
          name: "Phone I",
          value: "phone1",
        },
        {
          name: "Phone II",
          value: "phone2",
        },

        {
          name: "Type",
          value: "type",
        },
        {
          name: "Join Date",
          value: "join_date",
        },
        {
          name: "Balance",
          value: "balance",
        },
        {
          name: "Email",
          value: "email",
        },
        {
          name: "Company",
          value: "company",
        },
        {
          name: "Credit Limit",
          value: "credit_limit",
        },
        {
          name: "Last Purchase Date",
          value: "last_purchase",
        },
      ],
      checkAll: false,
      isIndeterminate: true,
      checkedColumns: [
        "customer_no",
        "company",
        "customer_name",
        "store",
        "phone1",
        "category",
        "balance",
      ],
      sorting_method: "",
      column_name: "",
    };
  },
  watch: {
    filter: function (n) {
      if (!n) {
        this.fetchData(1);
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Customers" },
    ]);
    globalEvents.$on("importCustomer", (type) => {
      this.fetchData(1);
      if (type) {
        this.fetchData(1);
      }
    });
  },
  created() {
    if(JwtService.getAttribute('user_data')){
        this.user = JSON.parse(JwtService.getAttribute('user_data'));
        
      }
    this.fetchData(1);
  },
  methods: {
    sortChange({ prop, order }) {
      console.log(order);
      this.sorting_method = order == "ascending" ? "ASC" : "DESC";
      this.column_name = prop;
      this.fetchData(1);
    },
    format_date(value){
         if (value) {
           return moment(String(value)).format('DD MMM YYYY')
          }
      },
    exportFile() {
      elTableExport(this.$refs.elTable, {
        fileName: "export-customer",
        type: "csv",
        useFormatter: true,
        withBOM: false,
      })
        .then(() => {
          console.info("ok");
        })
        .catch((err) => {
          console.info(err);
        });
    },
    getRowKeys(row) {
      return row.id;
    },
    searchUserHistory: function () {
      this.userContestBusy = true;
    },
    showAndHideColumn(column_name) {
      return this.$isColumnShow(column_name, this.checkedColumns);
    },
    updateStatus(customer_id, status) {
      changeStatus(customer_id, status).then((response) => {
        this.$showResponse("success", response.data.message);
      });
    },
    handleSizeChange(per_page) {
      this.pagination.per_page = per_page;
      this.fetchData(1);
    },
    handleCheckAllChange(val) {
      this.checkedColumns = [];
      if (val) {
        this.column_list.map((column, index) => {
          this.checkedColumns.push(column.value);
          console.log(index, this.checkedColumns);
        });
      }
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.column_list.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.column_list.length;
    },
    ImportCustomer() {
      this.$router.push({ path: "/customer/import" });
    },
    
    fetchData(p) {
      this.loading = true;
      let params = { page: p };
      if (this.filter) {
        params.filter = this.filter;
      }
      if (this.sort) {
        params.sort = this.sort;
      }
      if (this.pagination.per_page) {
        params.per_page = this.pagination.per_page;
      }
      if (this.selectedWebsite) {
        params.selected_website = this.selectedWebsite;
      }
      if (this.sorting_method) {
        params.sorting_method = this.sorting_method;
      }
      if (this.column_name) {
        params.column_name = this.column_name;
      }
      getAllCustomers(params).then((response) => {
        this.list = response.data.data.data;
        this.pagination.current_page = response.data.data.current_page;
        this.pagination.last_page = response.data.data.last_page;
        this.pagination.total_rows = response.data.data.total;
        this.loading = false;
        this.$set(this.list, "_showDetails", true);
      });

      getAllActiveSites(params).then((response) => {
        this.websites = response.data.data;
        this.loading = false;
      });
    },
    getWebsitevalues() {
      this.fetchData(1);
    },
    addCustomer() {
      this.$router.push({ path: "/customer/create" });
    },
    handleActions(evt, row) {
      if (evt === 1) {
        this.createAddress(row);
      } else if (evt === 2) {
        this.viewCustomer(row.id);
      } else if (evt === 3) {
        this.$router.push({ path: "/customer/edit/" + row.id });
      } else if (evt === 4) {
        this.$bvModal
          .msgBoxConfirm("Are you sure you want to delete ?", {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.loading = true;
              deleteCustomer(row.id).then((response) => {
                if (response.data.status) {
                  this.fetchData(1);
                  this.$showResponse("success", response.data.message);
                }
                this.loading = false;
              });
            } else {
              return false;
            }
          });
      } else if (evt === 5) {
        this.CreateCredit(row);
      }
      else if (evt === 6) {
        this.$modal.show(
        change_passwordVue,
        {
          customer_id: row.id,
        },
        {
          draggable: true,
          transition: "pop-out",
          overflow: "auto",
          height: "auto",
          clickToClose: false,
          adaptive: true,
          width: "400px",
        }
      );
      }
    },
    CreateCredit(row) {
      this.$modal.show(
        AddCredit,
        {
          customer_id: row.id,
        },
        {
          draggable: true,
          transition: "pop-out",
          overflow: "auto",
          height: "auto",
          clickToClose: false,
          adaptive: true,
          width: "800px",
        }
      );
    },
    viewCustomer(customer_id) {
      this.$router.push({ path: "/customer/view/" + customer_id });
    },
    createAddress(row) {
      this.$modal.show(
        CreateAddress,
        {
          customer_id: row.id,
        },
        {
          draggable: true,
          transition: "pop-out",
          overflow: "auto",
          height: "auto",
          clickToClose: false,
          adaptive: true,
          width: "600px",
        }
      );
    },
  },
};
</script>
